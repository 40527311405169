import { Component, Input, OnInit } from '@angular/core';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-products-niv1',
  templateUrl: './products-niv2.component.html',
  styleUrls: ['./products-niv2.component.css']
})
export class ProductsNiv2Component implements OnInit {

  public Url:string='https://paginas.virextec.com/archivos/'

  public webDatos:any;
  public productDatos:any;
  public seccionDatos:any;
  public seccionSola:any;

  constructor(private webService: WebService) { }

  ngOnInit(): void {
    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let nivel=window.location.hash.split('/')[2];
    console.log('mi nivel es: ', nivel );
    

    let scope = this;
    let dom = 'matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    let miPromesa = new Promise(function(resolve:any, reject:any) {
      resolve();      
    });

    miPromesa.then(function(){
      scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{
        let newObj1 = data.proAll.filter((value)=>{
          if(value.FamNom != null && value.NivID == '13'){
            return value.FamNom.indexOf(nivel) != -1 ? value:null  
          }          
          return value=null
        })

        scope.productDatos = newObj1;
        console.log('lista de productos en nivel 2', scope.productDatos);
        
      })
    })




  }

}
