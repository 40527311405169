<div class="page-head_agile_info_w3l" style="background-image: url('{{Url}}{{seccionDatos?.Img}}')">
    <div class="container">
        <h3 style="color: #a7c62c;"><span style="color: white;">Nuestras</span> {{seccionDatos?.Tit}}</h3>
        <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
                <ul class="w3_short">
                    <li><a routerLink="/">INICIO</a><i>|</i></li>
                    <li>{{seccionDatos?.Tit}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!--
<div class="banner_bottom_agile_info">
    <div class="container">            
        <div class="banner_bottom_agile_info_inner_w3ls">
            <div *ngFor="let sec of listaSecciones" class="col-md-6 wthree_banner_bottom_grid_three_left1 grid">
                <figure class="effect-roxy">
                    <img src="{{Url}}{{sec?.Img}}" alt=" " class="img-responsive" />
                    <figcaption>
                        <h3>Nuestros <span>{{sec?.Tit}}</span></h3>
                        <a routerLink="{{sec?.Url}}" class=""> <p>Ver {{sec?.Tit}}</p></a>
                    </figcaption>			
                </figure>
            </div>
            <div class="clearfix"></div>
        </div>        
     </div> 
</div>
-->

<div class="banner_bottom_agile_info">
    <div class="container">            
        <div class="banner_bottom_agile_info_inner_w3ls">
            <div *ngFor="let fam of listaFamilias" class="col-md-4  grid" style="padding-bottom: 5px; padding-top: 5px">
                <figure class="effect-roxy">
                    <img src="{{Url}}{{fam?.FamImg}}" alt=" " class="img-responsive" />
                    <figcaption style="border: 0;">
                        <h3><span>{{fam?.FamNom}}</span></h3>
                        <a routerLink="/productos:{{fam?.FamID}}" class=""> <p>Ver</p></a>
                    </figcaption>			
                </figure>
            </div>
            <div class="clearfix"></div>
        </div>        
     </div> 
</div>