import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from '../models/product';
import { MessengerService } from './messenger.service';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  constructor(private http: HttpClient) {}

  ngOnInit(){ }

  public getDatos(acction,dom,org,web,proTip,proMat,proMar,proFam,proBus): Observable<any>{ 
      
    return this.http.get<any>( 'https://paginas.virextec.com/servidor/MyWebSiteService.php',{
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      params: {acction:acction,dom:dom,org:org,web:web,proTip:proTip,proMat:proMat,proMar:proMar,proFam:proFam,proBus:proBus}
    });
  }
}
