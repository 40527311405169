import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public Url: string = 'https://paginas.virextec.com/archivos/';
  public webDatos: any;
  public productDatos: any;
  public seccionDatos: any;
  public seccionSola: any;
  public seccionRedes: any;
  public listMarcas: any[];
  public listTipos: any;
  public listMaterial: any;
  public listFamilias: any[];
  public marcasSeleccionadas: { [key: string]: boolean } = {};
  public familiasSeleccionadas: { [key: string]: boolean } = {};
  public productDatosFiltrados: any[];
  public filtroBusquedaMarca: string = '';
  public listMarcasFiltradas: any[];
  public filtroBusquedaFamilia: string = '';
  public listFamiliasFiltradas: any[];

  public paginaActual: number = 1;
  public productosPorPagina: number = 20; // Cambia esto según cuántos productos quieras por página
  public productosPaginados: Product[] = [];

  constructor(private webService: WebService, private msg: MessengerService) { }

  ngOnInit(): void {
    this.loadData();
    this.actualizarPaginacion();
  }

  actualizarPaginacion() {
    const inicio = (this.paginaActual - 1) * this.productosPorPagina;
    const fin = inicio + this.productosPorPagina;
    this.productosPaginados = this.productDatosFiltrados.slice(inicio, fin);
  }

  irAPagina(pagina: number) {
    this.paginaActual = pagina;
    this.actualizarPaginacion();
  }

  get totalPaginas() {
    return Math.ceil(this.productDatosFiltrados.length / this.productosPorPagina);
  }

  irAPaginaSiguiente() {
    if (this.paginaActual < this.totalPaginas) {
      this.paginaActual++;
      this.actualizarPaginacion();
    }
  }

  irAPaginaAnterior() {
    if (this.paginaActual > 1) {
      this.paginaActual--;
      this.actualizarPaginacion();
    }
  }

  filtrarMarcas() {
    this.listMarcasFiltradas = this.filtroBusquedaMarca ? this.listMarcas.filter(marca => marca.MarNom.toLowerCase().includes(this.filtroBusquedaMarca.toLowerCase())) : this.listMarcas;
    this.filtrarProductos();
    this.paginaActual = 1;
    this.actualizarPaginacion();
  }

  filtrarFamilias() {
    this.listFamiliasFiltradas = this.filtroBusquedaFamilia ? this.listFamilias.filter(familia => familia.FamNom.toLowerCase().includes(this.filtroBusquedaFamilia.toLowerCase())) : this.listFamilias;
    this.filtrarProductos();
    this.paginaActual = 1;
    this.actualizarPaginacion();
  }

  loadData() {
    let scope = this;
    let seccion = window.location.pathname.split('/')[1];
    let dom = 'matconaqp.com';
    let org = 9;
    let web = 8;

    if (localStorage.getItem("myProducts") && localStorage.getItem("mySections") && localStorage.getItem("myInformation")) {
      scope.productDatos = JSON.parse(localStorage.getItem('myProducts'));
      scope.webDatos = JSON.parse(localStorage.getItem('myInformation'));
      scope.seccionDatos = JSON.parse(localStorage.getItem('mySections'));
      scope.seccionRedes = JSON.parse(localStorage.getItem('myNetworks'));
      scope.productDatosFiltrados = [...scope.productDatos];

      let newObj = scope.seccionDatos.find(x => x.Url.includes(seccion));
      scope.seccionSola = newObj;

      if (!scope.listMarcas) {
        scope.webService.getDatos(0, dom, org, web, 0, 0, 0, 0, 0).subscribe((data) => {
          scope.listFamilias = data.familia;
          scope.listMarcas = data.marca;
          scope.listMarcasFiltradas = scope.listMarcas;
          scope.listFamiliasFiltradas = scope.listFamilias;
        })
      }


      scope.loadFilterData();
    } else {
      scope.webService.getDatos(0, dom, org, web, 0, 0, 0, 0, 0).subscribe((data) => {
        scope.webDatos = data.web[0];
        scope.seccionDatos = data.sections;
        scope.productDatos = data.proAll;
        scope.listFamilias = data.familia;
        scope.listMarcas = data.marca;
        scope.listMarcasFiltradas = scope.listMarcas;
        scope.listFamiliasFiltradas = scope.listFamilias;
        scope.productDatosFiltrados = [...scope.productDatos];

        localStorage.setItem('myProducts', JSON.stringify(data.proAll));
        localStorage.setItem('myInformation', JSON.stringify(data.web[0]));
        localStorage.setItem('mySections', JSON.stringify(data.sections));
        localStorage.setItem('myNetworks', JSON.stringify(data.redes));

        let newObj = scope.seccionDatos.find(x => x.Url.includes(seccion));
        scope.seccionSola = newObj;
        console.log(scope.seccionSola);

        scope.loadFilterData();
      });
    }
  }

  loadFilterData() {
    this.inicializarMarcasSeleccionadas();
    this.inicializarFamiliasSeleccionadas();
  }

  inicializarMarcasSeleccionadas() {
    this.listMarcas?.forEach(marca => {
      this.marcasSeleccionadas[marca.MarNom] = false;
    });
  }

  inicializarFamiliasSeleccionadas() {
    this.listFamilias?.forEach(familia => {
      this.familiasSeleccionadas[familia.FamNom] = false;
    });
  }

  onMarcaChange(marca: string, isChecked: boolean) {
    this.marcasSeleccionadas[marca] = isChecked;
    this.filtrarProductos();
  }

  onFamiliaChange(familia: string, isChecked: boolean) {
    this.familiasSeleccionadas[familia] = isChecked;
    this.filtrarProductos();
  }

  filtrarProductos() {
    const marcasActivas = Object.keys(this.marcasSeleccionadas).filter(key => this.marcasSeleccionadas[key]);
    const familiasActivas = Object.keys(this.familiasSeleccionadas).filter(key => this.familiasSeleccionadas[key]);

    this.productDatosFiltrados = this.productDatos.filter(producto =>
      (marcasActivas.length === 0 || marcasActivas.includes(producto.MarNom)) &&
      (familiasActivas.length === 0 || familiasActivas.includes(producto.FamNom))
    );

    this.actualizarPaginacion();
  }

  handleAddToCard(product: Product) {
    this.msg.sendMsg(product);
    this.cartItemFunc();
  }

  cartItem: number = 0;
  cartItemFunc() {
    if (localStorage.getItem('localCart')) {
      var cartCount = JSON.parse(localStorage.getItem('localCart'));
      this.cartItem = cartCount.length;
      this.msg.cartSubject.next(this.cartItem);
    }
  }
}
