<div class="page-head_agile_info_w3l" style="background-image: url('{{Url}}{{producDatos?.ProDirImg1}}')" >
    <div class="container">
        <h3><span>Pagina </span> {{seccionDatos?.Tit}}</h3>
        <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
                <ul class="w3_short">
                    <li><a routerLink="/">Inicio</a><i>|</i></li>
                    <li>{{seccionDatos?.Tit}} <i>|</i> {{producDatos?.ProNom}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>


  <!-- banner-bootom-w3-agileits -->
<div class="banner-bootom-w3-agileits">
	<div class="container">
	     <div class="col-md-4 single-right-left ">
			<div class="grid images_3_of_2">
				<div class="flexslider">					
					<ul class="slides">                        
						<li [attr.data-thumb]="Proimg2" >
							<div class="thumb-image"> <img *ngIf="Proimg2" [src]="Proimg2" data-imagezoom="true" class="img-responsive"> </div>
						</li>
						<li [attr.data-thumb]="Proimg3">
							<div class="thumb-image"> <img *ngIf="Proimg3" [src]="Proimg3" data-imagezoom="true" class="img-responsive"> </div>
						</li>	
						<li [attr.data-thumb]="Proimg4">
							<div class="thumb-image"> <img *ngIf="Proimg4" [src]="Proimg4" data-imagezoom="true" class="img-responsive"> </div>
						</li>
					</ul>
					<div class="clearfix"></div>
				</div>	
			</div>
		</div>
		<div class="col-md-8 single-right-left simpleCart_shelfItem">
			<h3>{{producDatos?.ProNom}}</h3>
			<p><span class="item_price" id="texto">{{producDatos?.ProDesCor}}</span></p>
			<div class="rating1">
				<span class="starRating">
                    <input id="rating5" type="radio" name="rating" value="5">
                    <label for="rating5">5</label>
                    <input id="rating4" type="radio" name="rating" value="4">
                    <label for="rating4">4</label>
                    <input id="rating3" type="radio" name="rating" value="3" checked="">
                    <label for="rating3">3</label>
                    <input id="rating2" type="radio" name="rating" value="2">
                    <label for="rating2">2</label>
                    <input id="rating1" type="radio" name="rating" value="1">
                    <label for="rating1">1</label>
				</span>
			</div>
            <div class="description">
                <h5>Check delivery, payment options and charges at your location</h5>
                <div class="col-md-12" style="margin: auto;">
                    <!--<div class="col-md-5">
                        <div class="col-md-4">
                            <span>Medida</span>                        
                        </div>
                        <div class="col-md-8">
                            <input class="form-control" type="text" name="medida" (change)="incMed(producDatos?.ProID, $event)" [value]="producDatos?.ProMed" autocomplete="off"/>    
                        </div>                        
                    </div>-->
                    <div class="col-md-4">
                        <div class="col-md-4">
                            <span>Cantidad</span>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control" type="number" name="cant" (change)="incCan(producDatos?.ProID, $event)" [value]="producDatos?.ProCan" autocomplete="off"/>
                        </div>                        
                    </div>                                        
                </div>
            </div>

            <div class="clearfix"> </div>
            <div class="occasional">
                <h5>Etiquetas :</h5> <br/>
                <div class="colr ert">                    
                    <label class="radio"><input type="radio" name="radio"><i></i>Marca: {{proMar?.MarNom}}</label>
                </div>
                <div class="colr">
                    <label class="radio"><input type="radio" name="radio"><i></i>Material: {{proMat?.MatNom}} </label>
                </div>
                <div class="colr">
                    <label class="radio"><input type="radio" name="radio"><i></i>Tipo: {{proTip?.TipNom}}</label>
                </div>
                <div class="clearfix"> </div>
            </div>
            <div class="occasion-cart">
                <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                    <form action="#" method="post">
                        <fieldset>
                            <input type="button" name="submit" value="Cotizar" class="button"
                            (click)="handleAddToCard(producDatos)">
                        </fieldset>
                    </form>
                </div>                                                                    
            </div>
            <ul class="social-nav model-3d-0 footer-social w3_agile_social single_page_w3ls">
                <li class="share">Compartir En : </li>
                <li *ngFor="let red of redesDatos"><a href="{{red?.RedLin}}" target="_blank" class="{{red?.RedDes}}">
                    <div class="front"><i class="{{red?.RedIco}}" aria-hidden="true"></i></div>
                    <div class="back"><i class="{{red?.RedIco}}" aria-hidden="true"></i></div></a>
                </li>                
            </ul>
					
		</div>
	 	<div class="clearfix"> </div>
	    <div class="responsive_tabs_agileits"> 
			<div id="horizontalTab">
                <ul class="resp-tabs-list">
                    <li>Descripcion</li>
                    <li>Contactenos</li>
                    <li>Mas Informacion</li>
                </ul>
                <div class="resp-tabs-container">
                    <div class="tab1">
                        <div class="single_page_agile_its_w3ls">
                            <h6>{{producDatos?.ProNom}}</h6>
                            <p id="texto">{{ producDatos?.ProDes}}</p>                            
                        </div>
                    </div>
                    <div class="tab2">                        
                        <div class="single_page_agile_its_w3ls">
                            <div class="bootstrap-tab-text-grids">
                                <div class="add-review">
                                    <h4>Añadir Comentario 222</h4>
                                   <!-- <form [formGroup]="aFormGroup">
                                        <input type="text" name="Name" required="Name">
                                        <input type="email" name="Email" required="Email"> 
                                        <textarea name="Message" required=""></textarea>
                                        <ngx-recaptcha2 #captchaElem
                                        [siteKey]="siteKey"
                                        formControlName="recaptcha">                                                
                                        </ngx-recaptcha2>
                                        <input type="submit" value="Enviar">
                                    </form>
                                -->
                                </div>
                            </div>                                
                        </div>
                    </div>

                    <div class="tab3">
                        <div class="single_page_agile_its_w3ls">
                            <h6>Familia: {{proFam?.FamNom}}</h6><p></p>
                            <p>{{proFam?.FamDes}}</p>

                            <h6>Material: {{proMat?.MatNom}}</h6>
                            <p>{{proMat?.MatDes}}</p>

                            <h6>Marca: {{proMar?.MarNom}}</h6>
                            <p>{{proMar?.MarDes}}</p>

                            <h6>Tipo: {{proTip?.TipNom}}</h6>
                            <p>{{proTip?.TipDes}}</p>                            
                        </div>
                    </div>
                </div>
			</div>	
		</div>
	
		<div class="w3_agile_latest_arrivals">
			<h3 class="wthree_text_info">{{ seccionDatos?.Tit}} <span>Similares</span></h3>	
            <div *ngFor="let simPro of productSimilar" class="col-md-3 product-men single">
                <div class="men-pro-item simpleCart_shelfItem">
                    <div class="men-thumb-item">
                        <img src="https://paginas.virextec.com/archivos/{{simPro?.ProDirImg2}}" alt="" class="pro-image-front">
                        <img src="https://paginas.virextec.com/archivos/{{simPro?.ProDirImg3}}" alt="" class="pro-image-back">
                        <div class="men-cart-pro">
                            <div class="inner-men-cart-pro">
                                <a href="/productos/{{simPro?.ProID}}" (click)="reLoad()" class="link-product-add-cart">Ver Producto</a>
                            </div>
                        </div>
                        <span class="product-new-top">-{{simPro?.ProPorOfe}}</span>                                
                    </div>
                    <div class="item-info-product ">
                        <h4><a href="/productos/{{simPro?.ProID}}" (click)="reLoad()">{{ simPro?.ProNom }}</a></h4>
                        <div class="info-product-price" *ngIf=" simPro?.ProPreOfe > 0 ">
                            <span class="item_price">S/.{{ simPro?.ProPreOfe }}</span>
                            <del>S/.{{ simPro?.ProPre }}</del>
                        </div>
                        <div class="info-product-price" *ngIf=" simPro?.ProPreOfe == 0 || !simPro?.ProPreOfe">
                            <span class="item_price">S/.{{ simPro?.ProPre }}</span>
                        </div>
                        <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                            <form action="#" method="post">
                                <fieldset>                                    
                                    <input type="button" name="submit" value="Cotizar" class="button"
                                    (click)="handleAddToCard(simPro)">
                                </fieldset>
                            </form>
                        </div>                                                                
                    </div>
                </div>
            </div>
		    <div class="clearfix"> </div>
		</div>
	</div>
</div>


