import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/services/web.service';
import { ProductComponent } from '../product/product.component';

@Component({
  selector: 'app-oferts',
  templateUrl: './offerts.component.html',
  styleUrls: ['./offerts.component.css']
})
export class OffertsComponent implements OnInit {

  constructor(private webService: WebService) { }

  public Url='https://paginas.virextec.com/archivos/';

  public empresaDatos:any;
  public redesDatos:any;  
  public seccionDatos:any;
  public listaSecciones:any;
  public listaFamilias:any;

  ngOnInit(): void {

    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let scope=this;   
    let seccion = window.location.pathname.split('/')[1];

    let dom='matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    scope.miPromesa.then(function(){
      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{
        console.log("mi data es: ", data)
        scope.empresaDatos = data.web[0];
        scope.redesDatos = data.redes;

        let newObj = data.sections.filter((value)=>{
          return value.Url.indexOf(seccion) != -1 ? value:null
        });

        scope.listaFamilias = data.familia

        console.log("object", scope.listaFamilias)

        scope.seccionDatos=newObj[0];

        let newObj2 = data.sections.filter((value)=>{
          return value.Ord.indexOf('2') != -1 ? value:null
        });
        scope.listaSecciones=newObj2;

        console.log("mi lista", scope.listaSecciones)
      })
    })
  }

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  });

}
