<div class="banner-bootom-w3-agileits">

	<div class="container">
        <div class="single-pro">
			<div *ngFor="let pro of productDatos" class="col-md-3">
                <div class="men-pro-item simpleCart_shelfItem" style="padding-bottom:0px; padding-top:8px;">  
                    <div class="item-info-product2 ">
                        <div>
                            <h4><a routerLink="/productos/{{pro?.FamNom}}">{{ pro?.ProNom }}</a></h4>
                            <h4><a routerLink="/producto/{{pro?.FamNom}}">{{ pro?.ProNom }} 22</a></h4>
                        </div>
                        <div class="snipcart-details hvr-outline-out">                            
                            <input type="button" name='submit' value='Ver Mas' class="button" routerLink='/productos/{{pro?.FamNom}}'/>                            
                        </div>                                                                                   
                    </div>
                </div>
            </div>			
			<div class="clearfix"></div>
		</div>
	</div>
</div>