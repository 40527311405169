import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

//import { HashLocationStrategy, LocationStrategy } from '@angular/common'

import { LocationStrategy, PathLocationStrategy } from '@angular/common';


import { NgxCaptchaModule  } from 'ngx-captcha';

import { AppComponent } from './app.component';

//===========SERVICES================
import { WebService } from './services/web.service';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/product/product.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { CartComponent } from './components/cart/cart/cart.component';
import { CartItemComponent } from './components/cart/cart-item/cart-item.component';
import { ProductsNiv2Component } from './components/products/products-niv2/products-niv2.component';
import { ProductsNiv1Component } from './components/products/products-niv1/products-niv1.component';
import { ProductsNiv3Component } from './components/products/products-niv3/products-niv3.component';
import { OffertsComponent } from './components/offerts/offerts.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    ProductComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    CartComponent,
    CartItemComponent,
    ProductsNiv2Component,
    ProductsNiv1Component,
    ProductsNiv3Component,
    OffertsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule
  ],
  //providers: [WebService],
  providers: [WebService, {provide: LocationStrategy, useClass: PathLocationStrategy}],

  //providers: [WebService, {provide: LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
