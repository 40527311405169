<div class="banner-bootom-w3-agileits">

	<div class="container">
        <div class="single-pro">
			<div *ngFor="let pro of productDatos" class="col-md-3 product-men">
                <div class="men-pro-item simpleCart_shelfItem">
                    <div class="men-thumb-item">
                        <img src="{{Url}}{{pro?.ProDirImg2}}" alt="" class="pro-image-front">
                        <img src="{{Url}}{{pro?.ProDirImg3}}" alt="" class="pro-image-back">
                        <div class="men-cart-pro">
                            <div class="inner-men-cart-pro">
                                <a routerLink="/productos/{{pro?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                            </div>
                        </div>
                        <span class="product-new-top">{{pro?.ProPorOfe}}</span>                            
                    </div>

                    <div class="item-info-product ">
                        <h4><a routerLink="/productos/{{pro?.FamNom}}/{{pro?.ProNom}}">{{ pro?.ProNom }}</a></h4>
                        <div class="info-product-price">
                            Desde <span class="item_price">{{ pro?.ProMedMin }}</span>
                            Hasta <span class="item_price">{{ pro?.ProMedMax }}</span>
                            <!--<del>{{ pro?.ProPreOfe }}</del>-->
                        </div>
                        <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                            <form action="#" method="post">
                                <fieldset>
                                    <input type="button" name="submit" value="Ver Producto" class="button" 
                                    routerLink='/productos/{{pro?.FamNom}}/{{pro?.ProNom}}'/>
                                </fieldset>
                            </form>
                        </div>                                                            
                    </div>
                </div>
            </div>			
			<div class="clearfix"></div>
		</div>
	</div>
</div>