import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Product } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { WebService } from 'src/app/services/web.service';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Input() product:Product;

  constructor(private webService: WebService, private msg:MessengerService) {}

  public Url='https://paginas.virextec.com/archivos/';

  public empresaDatos:any;
  public redesDatos:any;
  public productosDatos:any;
  public serviciosDatos:any;  
  public sliderDatos:any;
  public seccionDatos:any;
  public listaSecciones:any;
  public listaSecciones2:any;

  public listaConexiones:any;
  public listaValvulas:any;
  public listaTuberia:any;

  public listaNivel1:any;
  public listaNivel2:any;
  public listaNivel3:any;

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  })

  ngOnInit(): void {

    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.misScrip();
    this.miScrip2();

    let scope = this;

    scope.cartItemFunc();

    let link = window.location;
    let seccion =link.pathname.split('/')[1];

    let dom='matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    scope.miPromesa.then(function(){

      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{
        console.log(data);
          
        scope.empresaDatos = data.web[0];
        scope.redesDatos = data.rede;
        scope.serviciosDatos = data.servicios;
        scope.sliderDatos = data.slider;
        scope.listaSecciones2 = data.sections;

        localStorage.removeItem('myProducts');
        localStorage.removeItem('myNetworks');
        localStorage.removeItem('refresh');
        localStorage.setItem('myProducts', JSON.stringify(data.proAll));
        localStorage.setItem('myNetworks', JSON.stringify(data.redes));

        let newObj1 = data.sections.filter((value)=>{
          return value.Ord.indexOf('2') != -1 ? value:null
        });
        scope.listaSecciones=newObj1;          

        let newObjectNivel = data.proAll.filter((value)=>{
          if(value.NivID == '12'){
            //console.log(value);
            
            return value
          }
          else 
            return value=null
        })

        let newObj2 = newObjectNivel.sort((a,b)=>{            
          new Date(b.CreDat).getTime() - new Date(a.CreDat).getTime()
        });
        scope.productosDatos = newObj2;


        let newObj3 = data.proAll.filter((value)=>{ 
          if(value.FamNom != null && value.NivID == '12'){
            return value.FamNom.indexOf(data.familia[0].FamNom) != -1 ? value:null              
          }else             
            return  value=null
        });          
        scope.listaConexiones = newObj3;

        let newObj4 = data.proAll.filter((value)=>{
          if(value.FamNom != null && value.NivID == '12'){
            return value.FamNom.indexOf(data.familia[4].FamNom) != -1 ? value:null  
          }          
          return value=null
        });          
        scope.listaTuberia= newObj4;

        let newObj5 = data.proAll.filter((value)=>{            
          if(value.FamNom != null && value.NivID == '12'){
            return value.FamNom.indexOf(data.familia[3].FamNom) != -1 ? value:null  
          }          
          return value=null
        });
        scope.listaValvulas = newObj5;
      })

    })
  }

  
  handleAddToCard(product: Product){    
    this.msg.sendMsg(product);
    this.cartItemFunc();
  }

  cartItem:number = 0;
  cartItemFunc(){
    if(localStorage.getItem('localCart')!=null){
      var cartCount = JSON.parse(localStorage.getItem('localCart'));
      this.cartItem = cartCount.length; 
      this.msg.cartSubject.next(this.cartItem);
    }
  }


  misScrip(){

    $(document).ready(function () {
      
      $('#horizontalTab').easyResponsiveTabs({        
      type: 'default', //Types: default, vertical, accordion
      width: 'auto', //auto or any width like 600px
      fit: true,   // 100% fit in a container
      closed: 'accordion', // Start closed if in accordion view
      activate: function(event) { // Callback function if tab is switched
      var $tab = $(this);
      var $info = $('#tabInfo');
      var $name = $('span', $info);
      $name.text($tab.text());
      $info.show();
      }
      });
      $('#verticalTab').easyResponsiveTabs({
      type: 'vertical',
      width: 'auto',
      fit: true
      });

      $(".scroll").click(function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top},1000);
      });     

    });

    $('.counter').countUp();


    $(document).ready(function() {
      $().UItoTop({ easingType: 'easeOutQuart' });
    });

  }

  miScrip2(){

    $(window).load(function() {
      //console.log('CARGUE FLEX SLIDES');
      
      $('.flexslider').flexslider({
      animation: "slide",
      controlNav: "thumbnails"
      });
    }); 
  }
}
