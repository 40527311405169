<div id="top-products" class="page-head_agile_info_w3l" style="background-image: url('{{Url}}{{seccionSola?.Img}}')">
    <div class="container">
        <h3 style="color: #a7c62c;"><span style="color: white;">Nuestros </span> {{ seccionSola?.Tit }} </h3>
        <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
                <ul class="w3_short">
                    <li><a routerLink="/">Inicio</a><i>|</i></li>
                    <li>{{seccionSola?.Tit}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-2" style="margin-top: 30px;">

            <div class="panel panel-default">
                <div class="panel-heading">
                    <h5 class="panel-title">Marcas</h5>
                </div>
                <div class="panel-body">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Buscar..."
                            [(ngModel)]="filtroBusquedaMarca" (input)="filtrarMarcas()">
                    </div>

                    <div class="form-group" style="height: 150px; overflow-y: auto;">
                        <div class="checkbox" *ngFor="let marca of listMarcasFiltradas">
                            <label>
                                <input type="checkbox" [id]="marca.MarNom"
                                    [(ngModel)]="marcasSeleccionadas[marca.MarNom]"
                                    (change)="onMarcaChange(marca.MarNom, $event.target.checked)">
                                {{ marca.MarNom }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="panel-heading">
                    <h5 class="panel-title">Familias</h5>
                </div>
                <div class="panel-body">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Buscar..."
                            [(ngModel)]="filtroBusquedaFamilia" (input)="filtrarFamilias()">
                    </div>
                    <div class="form-group" style="height: 150px; overflow-y: auto;">
                        <div class="checkbox" *ngFor="let familia of listFamiliasFiltradas">
                            <label>
                                <input type="checkbox" [id]="'fam-' + familia.FamID"
                                    [(ngModel)]="familiasSeleccionadas[familia.FamNom]"
                                    (change)="onFamiliaChange(familia.FamNom, $event.target.checked)">
                                {{ familia.FamNom }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Otros paneles de filtro como precio y calificación del producto pueden ir aquí -->
        </div>

        <div class="col-md-10">
            <div class="">
                <div class="single-pro">
                    <div *ngFor="let pro of productosPaginados" class="col-md-3 product-men">
                        <div class="men-pro-item simpleCart_shelfItem">
                            <div class="men-thumb-item">
                                <img src="{{Url}}{{pro?.ProDirImg1}}" alt="" class="pro-image-front">
                                <img src="{{Url}}{{pro?.ProDirImg3}}" alt="" class="pro-image-back">
                                <div class="men-cart-pro">
                                    <div class="inner-men-cart-pro">
                                        <a routerLink="/productos/{{pro?.ProID}}" class="link-product-add-cart">Ver
                                            Producto</a>
                                    </div>
                                </div>
                                <span class="product-new-top">{{pro?.ProPorOfe}}</span>
                            </div>

                            <div class="item-info-product ">
                                <h4><a routerLink="/productos/{{pro?.ProID}}">{{ pro?.ProNom }}</a></h4>
                                <!--<h4><a [routerLink]="['/productos/', pro?.ProID ]">{{ pro?.ProNom }} 22</a></h4>-->

                                <div class="info-product-price" *ngIf=" pro?.ProPreOfe > 0 ">
                                    <span class="item_price">S/.{{ pro?.ProPreOfe }}</span>
                                    <del>S/.{{ pro?.ProPre }}</del>
                                </div>
                                <div class="info-product-price" *ngIf=" pro?.ProPreOfe == 0 || !pro?.ProPreOfe">
                                    <span class="item_price">S/.{{ pro?.ProPre }}</span>
                                </div>
                                <div
                                    class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                                    <form action="#" method="post">
                                        <fieldset>
                                            <input type="button" name="submit" value="Cotizar" class="button"
                                                (click)="handleAddToCard(pro)" />
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li [class.disabled]="paginaActual === 1">
                                    <a href="#" aria-label="Previous"
                                        (click)="irAPaginaAnterior(); $event.preventDefault()">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li *ngFor="let num of [].constructor(totalPaginas); let i = index"
                                    [class.active]="paginaActual === i+1">
                                    <a href="#" (click)="irAPagina(i+1); $event.preventDefault()">{{ i+1 }}</a>
                                </li>
                                <li [class.disabled]="paginaActual === totalPaginas">
                                    <a href="#" aria-label="Next"
                                        (click)="irAPaginaSiguiente(); $event.preventDefault()">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
            <!-- Tu código actual de productos aquí -->
        </div>

    </div>
</div>