import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { CartComponent } from './components/cart/cart/cart.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { ProductComponent } from './components/product/product.component';
import { ProductsNiv1Component } from './components/products/products-niv1/products-niv1.component';
//import { ProductsNiv2Component } from './components/products/products-niv2/products-niv2.component';
import { productDetailComponent } from './components/products/productDetail.component';
import { ProductsNiv3Component } from './components/products/products-niv3/products-niv3.component';
import { ProductsComponent } from './components/products/products.component';
import { OffertsComponent } from './components/offerts/offerts.component';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'productos', component: ProductsComponent},
  {path: 'productos2', component: ProductsNiv1Component},
  {path: 'productos/:id', component: ProductComponent},
  {path: 'producto/:id2', component: productDetailComponent},
  {path: 'ofertas', component: OffertsComponent},
  //{path: 'productos/:id/:id', component: ProductsNiv3Component},
  {path: 'nosotros', component:AboutComponent},
  {path: 'contactenos', component:ContactComponent},
  {path: 'cart', component:CartComponent},
  {path: '**', redirectTo:'', pathMatch:'full'}
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  //exports: [RouterModule]

  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ]
})
export class AppRoutingModule { }
