<div class="page-head_agile_info_w3l" style="background-image: url('{{Url}}{{seccionDatos?.Img}}')">
    <div class="container">
        <h3>{{seccionDatos?.Tit}}</h3>
        <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
                <ul class="w3_short">
                    <li><a routerLink="/">INICIO</a><i>|</i></li>
                    <li>{{seccionDatos?.Tit}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="banner_bottom_agile_info">
    <div class="container">
        <div class="contact-grid-agile-w3s">
            <div class="col-md-4 contact-grid-agile-w3">
                <div class="contact-grid-agile-w31">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <h4>Dirección</h4>
                    <p>{{empresaDatos?.WebSitDir1}}<span>{{empresaDatos?.WebSitDir2}}</span></p>
                </div>
            </div>
            <div class="col-md-4 contact-grid-agile-w3">
                <div class="contact-grid-agile-w32">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <h4>Llámanos</h4>
                    <p>{{empresaDatos?.WebSitNum1}}<span>{{empresaDatos?.WebSitNum2}}</span><span>{{empresaDatos?.WebSitNum3}}</span></p>
                </div>
            </div>
            <div class="col-md-4 contact-grid-agile-w3">
                <div class="contact-grid-agile-w33">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <h4>Email</h4>
                    <p><a routerLink="{{empresaDatos?.WebSitEma1}}" target="_blank">{{empresaDatos?.WebSitEma1}}</a>
                        <span><a routerLink="{{empresaDatos?.WebSitEma2}}" target="_blank">{{empresaDatos?.WebSitEma2}}</a></span>
                    </p>
                </div>
            </div>
            <div class="clearfix"> </div>
        </div>
    </div>
</div>

<div class="contact-w3-agile1 map" data-aos="flip-right">			
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15309.838813419396!2d-71.5302553!3d-16.4014631!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424b7ffc60290b%3A0x36b4a36677dae62d!2sRepresentaciones%20Matcon%20E.I.R.L!5e0!3m2!1sen!2spe!4v1698517749922!5m2!1sen!2spe" class="map" style="border:0" allowfullscreen=""></iframe>    
</div>

<div class="banner_bottom_agile_info">
	<div class="container">
	   <div class="agile-contact-grids">
			<div class="agile-contact-left">
				<div class="col-md-6 address-grid">
					<h4>Para Mas <span>Informacion</span></h4>
                    <div class="mail-agileits-w3layouts">
                        <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                        <div class="contact-right">
                            <p>Telefono </p><span>{{empresaDatos?.WebSitNum1}}</span>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="mail-agileits-w3layouts">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        <div class="contact-right">
                            <p>Mail </p><a href="{{empresaDatos?.WebSitEma1}}" target="_blank">{{empresaDatos?.WebSitEma1}}</a>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="mail-agileits-w3layouts">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <div class="contact-right">
                            <p>Dirección</p><span>{{empresaDatos?.WebSitDir1}}</span>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <ul class="social-nav model-3d-0 footer-social w3_agile_social two contact">
                        <li class="share">SIGUENOS EN : </li>
                        <li *ngFor="let red of redesDatos" ><a href="{{red?.RedLin}}" target="_blank" class="{{red?.RedDes}}">
                            <div class="front"><i class="{{red.RedIco}}" aria-hidden="true"></i></div>
                            <div class="back"><i class="{{red.RedIco}}" aria-hidden="true"></i></div></a>
                        </li>                            
                    </ul>
                </div>
                <div class="col-md-6 contact-form">
                    <h4 class="white-w3ls">Contactanos</h4>
                    <form  action="#" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
                        <div class="styled-input agile-styled-input-top">
                            <input type="text" [formControl]="name" name="Name" required autocomplete="off" />
                            <label>Nombre</label>
                            <span></span>
                        </div>
                        <div class="styled-input">
                            <input type="email"  [formControl]="email" name="Email" required autocomplete="off" /> 
                            <label>Email</label>
                            <span></span>
                        </div> 
                        <div class="styled-input">
                            <input type="text"  [formControl]="subject" name="Subject" required="" autocomplete="off" />
                            <label>Asunto</label>
                            <span></span>
                        </div>
                        <div class="styled-input">
                            <textarea name="Message"  [formControl]="message" required></textarea>
                            <label>Mensaje</label>
                            <span></span>
                        </div>
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [theme]="theme" formControlName="recaptcha"></ngx-recaptcha2>
                        <button [disabled]="" type="submit" (click)="onSubmit()">ENVIAR</button>

                        <!--<input type="submit" value="ENVIAR">-->

                        <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                            <span>{{responseMessage}}</span>
                        </div>

                        <div>
                            <div *ngIf="statusMsn === 2" class="alert alert-warning col-md-11" >{{msnSend}}</div>
                            <div *ngIf="statusMsn === 1" class="alert alert-success col-md-11" >{{msnSend}}</div>
                            <div *ngIf="statusMsn === 3" class="alert alert-info col-md-11" >{{msnSend}}</div>
                        </div>
                    </form>
                </div>
			</div>
		    <div class="clearfix"> </div>
	    </div>
    </div>
</div>