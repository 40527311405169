import { Component, OnChanges, Input } from '@angular/core';
import { Product } from 'src/app/models/product'; 
import { MessengerService } from 'src/app/services/messenger.service';
import { WebService } from 'src/app/services/web.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-productDetail',
  templateUrl: './productDetail.component.html',
  styleUrls: ['./productDetail.component.css']
})

export class productDetailComponent implements OnChanges {

    aFormGroup: FormGroup;
    siteKey:string="6LdD78caAAAAABTWk5xb_3e8NClqBnERDZW6agwP";

    @Input() productItem:Product;
    @Input() ruta: string;

    public listProducts:any;
    public producDatos:any;
    public productSimilar:any;
    public listSections:any;
    public seccionDatos:any;
    public redesDatos:any;
    public empresaDatos:any;

    public proMar:any;
    public proMat:any;
    public proTip:any;
    public proFam:any;

    public Url='https://paginas.virextec.com/archivos/';
    public myProduct =JSON.parse(localStorage.getItem('myProducts'));

    public Proimg1:any;
    public Proimg2:any;
    public Proimg3:any;
    public Proimg4:any;

    informacion: any;

    constructor(private route: ActivatedRoute, private webService: WebService, private msg:MessengerService, private formBuilder:FormBuilder) {
        
        this.route.params.subscribe(params=>{
            
            this.refresh();
            this.misScrip();
            this.cartItemFunc()  

            let top = document.getElementById('home');
            if (top !== null) {
            top.scrollIntoView();
            top = null;
            }

            this.aFormGroup = this.formBuilder.group({
                recaptcha: ['', Validators.required]
            })

            let scope=this;

            let dom='matconaqp.com';
            let org:number= 9;
            let web:number= 8;
            let pro:number= params.id2
            let section = window.location.pathname.split('/')[1];

            let tip:number=0;
            let mat:number=0;
            let mar:number=0;
            let fam:number=0;

            let miPromesa = new Promise(function(resolve:any, reject:any){
                resolve();
              });
            
            if( localStorage.getItem("myProducts") != null  && localStorage.getItem("mySections") != null && localStorage.getItem("myInformation") != null ){
                
                scope.listProducts = JSON.parse(localStorage.getItem('myProducts'));
                scope.empresaDatos = JSON.parse(localStorage.getItem('myInformation'));
                scope.listSections = JSON.parse(localStorage.getItem('mySections'))

                let myProduct = scope.listProducts.filter((value)=>{
                    return value.ProID.indexOf(pro) != -1 ? value:null
                });

                scope.producDatos = myProduct[0];
                scope.producDatos.ProCan=0;            
                scope.producDatos.ProMed='';          
        
                //scope.redesDatos=data.redes;        
                scope.proMar = scope.producDatos.MarNom;
                scope.proMat = scope.producDatos.MatNom;
                scope.proTip = scope.producDatos.TipNom;
                scope.proFam = scope.producDatos.FamNom;

                scope.Proimg1 = scope.Url + scope.producDatos.ProDirImg1;
                scope.Proimg2 = scope.Url + scope.producDatos.ProDirImg2;
                scope.Proimg3 = scope.Url + scope.producDatos.ProDirImg2;
                scope.Proimg4 = scope.Url + scope.producDatos.ProDirImg4;

                console.log("mi producto es..", myProduct)
                

                let newObj = scope.listSections .filter((value)=>{
                    return value.Url.indexOf(section) != -1 ? value:null
                });
                scope.seccionDatos=newObj[0];                
        
                let newObj22 = scope.listProducts.filter((value)=>{
                    return value.TipID.indexOf(scope.producDatos.TipID) == -1 ? value:null
                })
                scope.productSimilar = newObj22;

                console.log("productos similares...", scope.productSimilar )

                /*
                
        
                let newObj3 = data.marca.filter((value)=>{
                    return value.MarID.indexOf(data.proBus[0].MarID) != -1 ? value:null
                });
                scope.proMar=newObj3[0];
        
                let newObj4 = data.material.filter((value)=>{
                    return value.MatID.indexOf(data.proBus[0].MatID) != -1 ? value:null
                });
                scope.proMat=newObj4[0];
        
                let newObj5 = data.familia.filter((value)=>{
                    return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value:null
                });
                scope.proFam=newObj5[0];
        
                let newObj6 = data.tipo.filter((value)=>{
                    return value.TipID.indexOf(data.proBus[0].TipID) != -1 ? value:null
                });
                scope.proTip=newObj6[0];*/

               
            }else {
               /* miPromesa.then(function(){
                    scope.webService.getDatos(0, dom, org, web,0,0,0,0, pro).subscribe((data)=>{
                    tip=data.proBus[0].TipID;
                    mat=data.proBus[0].MatID;
                    mar=data.proBus[0].MarID;
                    fam=data.proBus[0].FamID;   
                    
            
                    scope.webService.getDatos(0,dom,org,web,tip,mat,mar,fam,pro).subscribe((data)=>{
                        console.log('data', data)
                        scope.producDatos=data.proBus[0]; 
                
                        scope.producDatos.ProCan=0;            
                        scope.producDatos.ProMed='';          
                
                        scope.redesDatos=data.redes;
                        scope.empresaDatos=data.web[0];
                
                        /*let newObj = data.sections.filter((value)=>{
                            return value.Url.indexOf(seccion) != -1 ? value:null
                        });
    
                        scope.seccionDatos=newObj[0];*/

                        /*
                
                        let newObj2 = data.productos.filter((value)=>{
                            return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value:null
                        });
                        
                
                        let newObj22 = newObj2.filter((value)=>{
                            return value.ProID.indexOf(data.proBus[0].ProID) == -1 ? value:null
                        })
                        scope.productSimilar=newObj22;
                        
                
                        let newObj3 = data.marca.filter((value)=>{
                            return value.MarID.indexOf(data.proBus[0].MarID) != -1 ? value:null
                        });
                        scope.proMar=newObj3[0];
                
                        let newObj4 = data.material.filter((value)=>{
                            return value.MatID.indexOf(data.proBus[0].MatID) != -1 ? value:null
                        });
                        scope.proMat=newObj4[0];
                
                        let newObj5 = data.familia.filter((value)=>{
                            return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value:null
                        });
                        scope.proFam=newObj5[0];
                
                        let newObj6 = data.tipo.filter((value)=>{
                            return value.TipID.indexOf(data.proBus[0].TipID) != -1 ? value:null
                        });
                        scope.proTip=newObj6[0];
                    
                        })
                    })      
                })*/
            }    

            console.log('my paramns', params)
        })
    }

    ngOnChanges() {
        console.log('route', this.route)
        this.Proimg1 = "ProImg1 escrityo  por mua xD"
        this.route.data.subscribe(data => {
            console.log( 'mi data es...', data)
        this.informacion = data.informacion;
        });
    }

    handleAddToCard(producto:Product){    
        this.msg.sendMsg(producto)
        this.cartItemFunc();  
    }

    cartItem:number = 0;
    cartItemFunc(){
        if(localStorage.getItem('localCart')!=null){
            var cartCount = JSON.parse(localStorage.getItem('localCart'));
            this.cartItem = cartCount.length; 
            this.msg.cartSubject.next(this.cartItem);
        }
    }

    incMed(proID, e){
        this.producDatos.ProMed = e.target.value;
        console.log(this.producDatos.ProMed);    
    }

    incCan(proID, e){      
        this.producDatos.ProCan = e.target.value;
        console.log(this.producDatos.ProCan);
    }

    refresh(){
        let refresh = window.localStorage.getItem('refresh');
        if (refresh===null){
            window.localStorage.setItem('refresh', "1");
            window.location.reload();     
        }
    }

    reLoad(){
        let top = document.getElementById('home');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }

        function delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }

        (async () => { 
            // Do something before delay
            console.log('before delay')

            await delay(100);

            // Do something after
            console.log(window.location);
            window.location.reload();
            })();  
        }

    misScrip(){
        $(document).ready(function () {
            $('#horizontalTab').easyResponsiveTabs({
            type: 'default', //Types: default, vertical, accordion
            width: 'auto', //auto or any width like 600px
            fit: true,   // 100% fit in a container
            closed: 'accordion', // Start closed if in accordion view
            activate: function(event) { // Callback function if tab is switched
            var $tab = $(this);
            var $info = $('#tabInfo');
            var $name = $('span', $info);
            $name.text($tab.text());
            $info.show();
            }
            });
            $('#verticalTab').easyResponsiveTabs({
            type: 'vertical',
            width: 'auto',
            fit: true
            });

            $('.flexslider').flexslider({
            animation: "slide",
            controlNav: "thumbnails"
            });
        }); 
    }
}