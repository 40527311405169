import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  siteKey:string='6Lex9wspAAAAAHhnXQcUs-JQT08vXq42jlrCdgGZ';
  theme:string ='dark';

  form: FormGroup;
  name: FormControl = new FormControl("", [Validators.required]);
  email: FormControl = new FormControl("", [Validators.required]);
  subject: FormControl = new FormControl("", [Validators.required]);
  message: FormControl = new FormControl("", [Validators.required]);
  recaptcha: FormControl = new FormControl ('', [Validators.required]);
  
  responseMessage: string;
  
  token: string = "";

  honeypot: FormControl = new FormControl("");
  submitted: boolean = false;
  isLoading: boolean = false;
  
  constructor(private _renderer: Renderer2, private webService:WebService, private formBuilder:FormBuilder, private http: HttpClient) {

    this.form = this.formBuilder.group({
      recaptcha:this.recaptcha,
      name: this.name,
      email: this.email,
      message: this.message,
      subject: this.subject
    });
   }

  public Url='https://paginas.virextec.com/archivos/';

  public empresaDatos:any;
  public redesDatos:any;  
  public seccionDatos:any;
  public listaSecciones:any;

  ngOnInit(): void {    
    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    

    let script = this._renderer.createElement('scrip');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js";
    this._renderer.appendChild(document.body, script);

    let scope=this;

    let seccion = window.location.pathname.split('/')[1];    
    //console.log("pa", pathArray)
    //let seccion = pathArray[0];

    let dom='matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    scope.miPromesa.then(function(){
      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{
        scope.empresaDatos = data.web[0];
        scope.redesDatos = data.redes;

        let newObj = data.sections.filter((value)=>{
          return value.Url.indexOf(seccion) != -1 ? value:null
        });

        scope.seccionDatos=newObj[0];
        console.log(scope.seccionDatos)
      })
    })
  }

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  });

  public statusMsn:number=0;
  public msnSend:string='';
  
  onSubmit() {
    let nameOrigin:string = this.empresaDatos.Tit;
    let emailOrigin:string = this.empresaDatos.WebSitEma2;    
    let logo:string = 'https://paginas.virextec.com/archivos/empresa/REPRESENTACIONES MATCON AQP-logos.png';
    let dominio:string = "https://matconaqp.com";
    
    if (this.form.status == "VALID") {
      this.form.disable(); 
      
      var formData: any = new FormData();

      formData.append("name", this.form.get("name").value);
      formData.append("email", this.form.get("email").value);
      formData.append("message", this.form.get("message").value);
      formData.append("subject", this.form.get("subject").value);
      formData.append("nameOrigin", nameOrigin);
      formData.append("emailOrigin", emailOrigin);
      formData.append("logo", logo);
      formData.append("dominio", dominio);

      console.log('DATOS DE ENVIO AL SERVICIO',formData);
      
      this.http.post('https://paginas.virextec.com/servidor/MyMailService.php', formData).subscribe(
        (response) => {

          if (response == "Succes") {
            this.statusMsn = 1;
            this.msnSend="SU MENSAJE FUE ENVIADO CON SATISFACCION";          
            //this.responseMessage = "Thanks for the message! I'll get back to you soon!";            
          } else {
            console.log("ERROR EN EL ENVIO DE SU MENSAJE" , response);
            //this.responseMessage = "Oops! Something went wrong... Reload the page and try again.";
          }
        },
        (error) => {
          if (error == "Succes") {
            this.statusMsn = 1;
            this.msnSend="SU MENSAJE FUE ENVIADO CON SATISFACCION";         
            //this.responseMessage = "Thanks for the message! I'll get back to you soon!";            
          } else {
            console.log("ERROR EN EL ENVIO DE SU MENSAJE" , error);
            //this.responseMessage = "Oops! Something went wrong... Reload the page and try again.";
          }
        }
      );
    } else{
      if(this.statusMsn == 1){
        this.statusMsn=3;
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }if(this.statusMsn == 3){
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }else{
        this.statusMsn=2
        this.msnSend="El formulario no fue completado...";
      }
    }
  } 

}
