import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  aFormGroup: FormGroup;
  siteKey:string="6Lex9wspAAAAAHhnXQcUs-JQT08vXq42jlrCdgGZ";
  name: FormControl = new FormControl("", [Validators.required]);
  email: FormControl = new FormControl("", [Validators.required]);
  recaptcha: FormControl = new FormControl ('', [Validators.required]);

  cartItems = [];
  getCartDetails=[];

  empresa:any = {};

  cartTotal = 0;

  Url='https://paginas.virextec.com/archivos/';

  constructor(private msg: MessengerService, 
    private formBuildeer: FormBuilder, 
    private http: HttpClient,
    private webService:WebService) {

    this.aFormGroup = this.formBuildeer.group({
      //recaptcha: ['', Validators.required],
      recaptcha:this.recaptcha,
      name:this.name,
      email: this.email      
    });

  }

  ngOnInit(){    
    
    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.msg.getMsg().subscribe((product:Product) =>{
      this.addProductoToCard(product)
      this.addCart(product)  
      this.loadCart();
    })
    
    this.cartDetails(); 
    
    let scope = this;
    let dom='matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    scope.miPromesa.then(function(){
      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{
        scope.empresa = data.web[0];        
      })
    })
  }

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  });

  addProductoToCard(product: Product){

    let productExist = false

    for(let i in this.cartItems){
      if(this.cartItems[i].ProID == product.ProID){        
        productExist=true;
        break;
      }
    }

    if(!productExist){
      this.cartItems.push({
        ProID: Number(product.ProID),
        ProCod: product.ProCod,
        ProNom: product.ProNom,
        ProDes: product.ProDes,
        ProDirImg1: product.ProDirImg1
      })
    }  

    this.cartTotal=0
  }

  cartItem2:any = [];

  addCart(product){    
    let cartDataNull = localStorage.getItem('localCart');
    if(cartDataNull == null){ 
      let storeDataGet: any = [];
      storeDataGet.push(product);
      localStorage.setItem('localCart', JSON.stringify(storeDataGet));
    }
    else{
      var id = product.ProID;      
      let index:number = -1;
      this.cartItem2 = JSON.parse(localStorage.getItem('localCart')); 
           
      for(let i=0; i<this.cartItem2.length; i++){
        if(parseInt(id) === parseInt(this.cartItem2[i].ProID)){
          //this.cartItem2[i].ProCan = 0;
          index = i;
          break;          
        }
      }
      if(index == -1){
        this.cartItem2.push(product)
        localStorage.setItem('localCart', JSON.stringify(this.cartItem2));
      }
      else{
        localStorage.setItem('localCart', JSON.stringify(this.cartItem2));
      }
    }
  }
  
  cartDetails(){
    if(localStorage.getItem('localCart')){
      this.getCartDetails = JSON.parse(localStorage.getItem('localCart'));   
    }
  }


  incCan(proID, e) {
    for(let i=0; i<this.getCartDetails.length; i++){
      if(this.getCartDetails[i].ProID === proID ){
        this.getCartDetails[i].ProCan = parseInt(e.target.value);      
      }
    }
    localStorage.setItem('localCart', JSON.stringify(this.getCartDetails));      
  }

  incMed(proID, e) {

    for(let i=0; i<this.getCartDetails.length; i++){
      if(this.getCartDetails[i].ProID === proID ){
        this.getCartDetails[i].ProMed = e.target.value;        
      }
    }
    localStorage.setItem('localCart', JSON.stringify(this.getCartDetails));    
  }

  total:number=0;
  loadCart(){
    if(localStorage.getItem('localCart')){
      this.getCartDetails = JSON.parse(localStorage.getItem('localCart'));
      this.total = this.getCartDetails.reduce(function(acc, val){
        return acc + (val.ProCan*1)
      },0);
    }
  }

  removeAll(){    
    localStorage.removeItem('localCart');
    this.getCartDetails = [];
    this.total=0;
    this.cartItem = 0;
    this.msg.cartSubject.next(this.cartItem);
  }

  singleDeleter(proID){
    console.log('entro a eleiminar 1', proID);
    if(localStorage.getItem('localCart')){
      this.getCartDetails = JSON.parse(localStorage.getItem('localCart'));
      for(let i=0; i<this.getCartDetails.length; i++){
        if(this.getCartDetails[i].ProID === proID){
          this.getCartDetails.splice(i, 1);
          localStorage.setItem('localCart',JSON.stringify(this.getCartDetails));
          this.loadCart();
          this.cartItemFunc();
        }
      }
    }    
  }

  cartItem:number = 0;
  cartItemFunc(){
    var cartCount = JSON.parse(localStorage.getItem('localCart'));
    this.cartItem = cartCount.length; 
    this.msg.cartSubject.next(this.cartItem);    
  }

  public msnSend:string='';
  public statusMsn:number=0;

  sendCart(){

    let nameOrigin:string = this.empresa.Tit;
    let emailOrigin:string = this.empresa.WebSitEma2;
    let logo:string = 'https://paginas.virextec.com/archivos/empresa/REPRESENTACIONES MATCON AQP-logos.png';
    let dominio:string = "https://matconaqp.com";

    console.log('MI CARRITO', JSON.parse(localStorage.getItem('localCart')))

    var myCart:[]=JSON.parse(localStorage.getItem('localCart'));
    console.log(myCart);

    if(this.aFormGroup.status == "VALID"){
      this.aFormGroup.disable();

      var formData:any = new FormData();

      formData.append("name", this.aFormGroup.get("name").value),
      formData.append("email", this.aFormGroup.get("email").value);
      formData.append("nameOrigin", nameOrigin);
      formData.append("emailOrigin", emailOrigin);
      formData.append("logo", logo);
      formData.append("dominio", dominio);
      formData.append("subject", 'COTIZACION');
      formData.append("myCart",JSON.stringify(myCart));
      //console.log('mi carro', myCart);
      

      this.http.post('https://paginas.virextec.com/servidor/MyMailService.php',formData).subscribe((response)=>{
        
                            
        // choose the response message
        if (response == "Succes") {
          this.statusMsn = 1;
          this.msnSend="SU MENSAJE FUE ENVIADO CON SATISFACCION";
          this.removeAll();          
          //this.responseMessage = "Thanks for the message! I'll get back to you soon!";            
        } else {
          console.log("ERROR EN EL ENVIO DE SU MENSAJE" , response);
          //this.responseMessage = "Oops! Something went wrong... Reload the page and try again.";
        }
      },(error)=>{
        if (error == "Succes") {
          this.statusMsn = 1;
          this.msnSend="SU MENSAJE FUE ENVIADO CON SATISFACCION";
          this.removeAll();          
          //this.responseMessage = "Thanks for the message! I'll get back to you soon!";            
        } else {
          console.log("ERROR EN EL ENVIO DE SU MENSAJE" , error);
          //this.responseMessage = "Oops! Something went wrong... Reload the page and try again.";
        }
      })
    }else{
      if(this.statusMsn == 1){
        this.statusMsn=3;
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }if(this.statusMsn == 3){
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }else{
        this.statusMsn=2
        this.msnSend="El formulario no fue completado...";
      }
    }
  }
}
