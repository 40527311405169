import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class MessengerService{

    subject = new Subject()

    constructor(){}

    sendMsg(product){          
        this.subject.next(product) 
        //localStorage.setItem('localCart',JSON.stringify(product));     
    }

    getMsg(){
        return this.subject.asObservable()
    }

    cartSubject = new Subject<any>();

}