<div class="page-head_agile_info_w3l" style="background-image: url('{{Url}}{{seccionDatos?.Img}}')">
    <div class="container">
        <h3 style="color: #a7c62c;"><span style="color: white;">Acerca de</span> {{seccionDatos?.Tit}}</h3>
        <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
                <ul class="w3_short">
                    <li><a routerLink="/">INICIO</a><i>|</i></li>
                    <li>{{seccionDatos?.Tit}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="banner_bottom_agile_info">
    <div class="container">
        <div class="agile_ab_w3ls_info">
            <div class="col-md-4 ab_pic_w3ls">
                <img src="{{Url}}{{empresaDatos?.WebSitLog}}" alt=" " class="img-responsive" />
            </div>
            <div class="col-md-8 ab_pic_w3ls_text_info">
                <h5>{{empresaDatos?.Tit}}</h5>
                <p id="texto">{{empresaDatos?.Des}}</p>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="agile_ab_w3ls_info">
            <div class="col-md-8 ab_pic_w3ls_text_info">
                <h5>MISIÓN</h5>
                <p id="texto">{{empresaDatos?.WebSitMis}}</p>
            </div>
            <div class="col-md-4 ab_pic_w3ls">
                <img src="../../../assets/images/1.png" alt=" " class="img-responsive" />
            </div>
            <div class="clearfix"></div>
        </div>

        <div class="agile_ab_w3ls_info">
            <div class="col-md-4 ab_pic_w3ls">
                <img src="../../../assets/images/2.png" alt=" " class="img-responsive" />
            </div>
            <div class="col-md-8 ab_pic_w3ls_text_info">
                <h5>VISIÓN</h5>
                <p id="texto">{{empresaDatos?.WebSitVis}}</p>
            </div>
            <div class="clearfix"></div>
        </div>

        <div class="agile_ab_w3ls_info">
            <div class="col-md-8 ab_pic_w3ls_text_info">
                <h5>VALORES</h5>
                <p id="texto">{{empresaDatos?.WebSitVal}}</p>
            </div>
            <div class="col-md-4 ab_pic_w3ls">
                <img src="../../../assets/images/3.png" alt=" " class="img-responsive" />
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>