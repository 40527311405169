import { Component, Input, OnInit } from '@angular/core';
import { WebService } from 'src/app/services/web.service';
import { Product } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {

  aFormGroup: FormGroup;
  siteKey: string = "6Lex9wspAAAAAHhnXQcUs-JQT08vXq42jlrCdgGZ";

  @Input() productItem: Product

  public listProducts: any;
  public producDatos: any;
  public productSimilar: any;
  public listSections: any;
  public seccionDatos: any;
  public redesDatos: any;
  public empresaDatos: any;

  public proMar: any;
  public proMat: any;
  public proTip: any;
  public proFam: any;

  public Url = 'https://paginas.virextec.com/archivos/';
  public myProduct = JSON.parse(localStorage.getItem('myProducts'));

  public Proimg1: any;
  public Proimg2: any;
  public Proimg3: any;
  public Proimg4: any;

  constructor(private webService: WebService, private msg: MessengerService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.refresh();
    this.misScrip();
    this.cartItemFunc()

    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    })

    let scope = this;

    let pathArray = window.location.pathname.split('/');
    let seccion = pathArray[1];
    let pro = pathArray[2];

    if (JSON.parse(localStorage.getItem('myProducts'))) {
      for (let i = 0; i < this.myProduct.length; i++) {
        if (this.myProduct[i].ProID == pro) {

          this.Proimg1 = this.Url + this.myProduct[i].ProDirImg1;
          this.Proimg2 = this.Url + this.myProduct[i].ProDirImg2;
          this.Proimg3 = this.Url + this.myProduct[i].ProDirImg3;
          this.Proimg4 = this.Url + this.myProduct[i].ProDirImg4;
        }
      }
    }

    let dom = 'matconaqp.com';
    let org: number = 9;
    let web: number = 8;

    let tip: number = 0;
    let mat: number = 0;
    let mar: number = 0;
    let fam: number = 0;    

    if (localStorage.getItem("myProducts") != null && localStorage.getItem("mySections") != null && localStorage.getItem("myInformation") != null) {

      //console.log("ENTRO ACAA..")

      scope.listProducts = JSON.parse(localStorage.getItem('myProducts'));
      scope.empresaDatos = JSON.parse(localStorage.getItem('myInformation'));
      scope.listSections = JSON.parse(localStorage.getItem('mySections'))
      scope.redesDatos = JSON.parse(localStorage.getItem('myNetworks'))

      let myProduct = scope.listProducts.filter((value) => {
        return value.ProID.indexOf(pro) != -1 ? value : null
      });

      scope.producDatos = myProduct[0];
      scope.producDatos.ProCan = 0;
      scope.producDatos.ProMed = '';

      scope.proMar = scope.producDatos.MarNom;
      scope.proMat = scope.producDatos.MatNom;
      scope.proTip = scope.producDatos.TipNom;
      scope.proFam = scope.producDatos.FamNom;

      scope.Proimg1 = scope.Url + scope.producDatos.ProDirImg1;
      scope.Proimg2 = scope.Url + scope.producDatos.ProDirImg2;
      scope.Proimg3 = scope.Url + scope.producDatos.ProDirImg3;
      scope.Proimg4 = scope.Url + scope.producDatos.ProDirImg4;

      let similars = scope.listProducts.filter((value) => {
        let famID = parseInt(value.FamID)
        return famID == scope.producDatos.FamID && value.ProID !== scope.producDatos.ProID ? value : null;
      })
      scope.productSimilar = similars;

    } else {

      let miPromesa = new Promise(function (resolve: any, reject: any) {
        resolve();
      });
      miPromesa.then(function () {
        scope.webService.getDatos(0, dom, org, web, 0, 0, 0, 0, pro).subscribe((data) => {
          //console.log("DATA LLEGANDO", data)
          tip = data.proBus[0].TipID;
          mat = data.proBus[0].MatID;
          mar = data.proBus[0].MarID;
          fam = data.proBus[0].FamID;   

          scope.listProducts = data.proAll;
          scope.empresaDatos = data.web2[0];
          scope.listSections = data.sections;

          let myProduct = scope.listProducts.filter((value) => {
            return value.ProID.indexOf(pro) != -1 ? value : null
          });

          scope.producDatos = myProduct[0];
          scope.producDatos.ProCan = 0;
          scope.producDatos.ProMed = '';

          //console.log("MI PRODUCTO ES...", scope.producDatos)

          scope.proMar = scope.producDatos.MarNom;
          scope.proMat = scope.producDatos.MatNom;
          scope.proTip = scope.producDatos.TipNom;
          scope.proFam = scope.producDatos.FamNom;

          scope.Proimg1 = scope.Url + scope.producDatos.ProDirImg1;
          scope.Proimg2 = scope.Url + scope.producDatos.ProDirImg2;
          scope.Proimg3 = scope.Url + scope.producDatos.ProDirImg2;
          scope.Proimg4 = scope.Url + scope.producDatos.ProDirImg4;

          let similars = scope.listProducts.filter((value) => {
            let famID = parseInt(value.FamID)
            return famID == scope.producDatos.FamID && value.ProID !== scope.producDatos.ProID ? value : null;
          })
          scope.productSimilar = similars;

          scope.redesDatos = data.redes;

          //console.log("SIMILARES", scope.productSimilar)

          /*scope.webService.getDatos(0, dom, org, web, tip, mat, mar, fam, pro).subscribe((data) => {

            scope.producDatos = data.proBus[0];

            scope.producDatos.ProCan = 0;
            scope.producDatos.ProMed = '';

            

            let newObj = data.sections.filter((value) => {
              return value.Url.indexOf(seccion) != -1 ? value : null
            });
            scope.seccionDatos = newObj[0];

            let newObj2 = data.productos.filter((value) => {
              return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value : null
            });


            let newObj22 = newObj2.filter((value) => {
              return value.ProID.indexOf(data.proBus[0].ProID) == -1 ? value : null
            })
            scope.productSimilar = newObj22;


            let newObj3 = data.marca.filter((value) => {
              return value.MarID.indexOf(data.proBus[0].MarID) != -1 ? value : null
            });
            scope.proMar = newObj3[0];

            let newObj4 = data.material.filter((value) => {
              return value.MatID.indexOf(data.proBus[0].MatID) != -1 ? value : null
            });
            scope.proMat = newObj4[0];

            let newObj5 = data.familia.filter((value) => {
              return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value : null
            });
            scope.proFam = newObj5[0];

            let newObj6 = data.tipo.filter((value) => {
              return value.TipID.indexOf(data.proBus[0].TipID) != -1 ? value : null
            });
            scope.proTip = newObj6[0];

          })*/



        })
      })
    }
  }

  handleAddToCard(producto: Product) {
    this.msg.sendMsg(producto)
    this.cartItemFunc();
  }

  cartItem: number = 0;
  cartItemFunc() {
    if (localStorage.getItem('localCart') != null) {
      var cartCount = JSON.parse(localStorage.getItem('localCart'));
      this.cartItem = cartCount.length;
      this.msg.cartSubject.next(this.cartItem);
    }
  }

  incMed(proID, e) {
    this.producDatos.ProMed = e.target.value;
    //console.log(this.producDatos.ProMed);

  }

  incCan(proID, e) {
    this.producDatos.ProCan = e.target.value;
    //console.log(this.producDatos.ProCan);
  }

  refresh() {
    let refresh = window.localStorage.getItem('refresh');
    if (refresh === null) {
      window.localStorage.setItem('refresh', "1");
      window.location.reload();
    }
  }

  reLoad() {
    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    (async () => {
      // Do something before delay
      //console.log('before delay')

      await delay(100);

      // Do something after
      //console.log(window.location);
      window.location.reload();
    })();
  }

  misScrip() {
    $(document).ready(function () {
      $('#horizontalTab').easyResponsiveTabs({
        type: 'default', //Types: default, vertical, accordion
        width: 'auto', //auto or any width like 600px
        fit: true,   // 100% fit in a container
        closed: 'accordion', // Start closed if in accordion view
        activate: function (event) { // Callback function if tab is switched
          var $tab = $(this);
          var $info = $('#tabInfo');
          var $name = $('span', $info);
          $name.text($tab.text());
          $info.show();
        }
      });
      $('#verticalTab').easyResponsiveTabs({
        type: 'vertical',
        width: 'auto',
        fit: true
      });

      $('.flexslider').flexslider({
        animation: "slide",
        controlNav: "thumbnails"
      });
    });
  }
}
