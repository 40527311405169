<div class="header" id="home">
	<div class="container">
		<ul>
			<li> <a href="#" data-toggle="modal" data-target="#myModal"><i class="fa fa-unlock-alt"
						aria-hidden="true"></i> Sign In </a></li>
			<li> <a href="#" data-toggle="modal" data-target="#myModal2"><i class="fa fa-pencil-square-o"
						aria-hidden="true"></i> Sign Up </a></li>
			<li><i class="fa fa-phone" aria-hidden="true"></i> {{ webDatos?.WebSitNum1 }}</li>
			<li><i class="fa fa-envelope-o" aria-hidden="true"></i> <a href="{{ webDatos?.WebSitEma1 }}"
					target="_blank">{{ webDatos?.WebSitEma1 }}</a></li>
		</ul>
	</div>
</div>

<div class="header-bot d-flex align-items-center">
	<div class="header-bot_inner_wthreeinfo_header_mid">
		<div class="col-md-4 header-middle">
			<form (input)="buscarProducto(searchQuery.value)">
				<input type="search" name="search" placeholder="Buscar.." required="" #searchQuery autocomplete="off">
				<input type="submit" value=" " #searchQuery>
				<div class="clearfix"></div>
			</form>

			<div class="col-md-10 resultados-busqueda"
				*ngIf="resultadosBusqueda.length > 0 && searchQuery.value.trim() !== ''" style=" border: none; border-radius: 20px; outline: none;">
				<table class="table" >
					<tr *ngFor="let producto of resultadosBusqueda">
						<td>
							<a routerLink="/productos/{{ producto.ProID }}">
								<img src="{{ Url + producto.ProDirImg1 }}" alt="{{ producto.ProNom }}" width="50">
							</a>
						</td>
						<td>
							<a routerLink="/productos/{{ producto.ProID }}">{{
								producto.ProNom }}</a>
						</td>

					</tr>
				</table>
			</div>
		</div>
		<!-- header-bot -->
		<div class="col-md-4 logo_agile text-center">
			<!--<a href="/" title="{{webDatos?.Tit}}"><img src="https://paginas.virextec.com/archivos/empresa/Riegos%20y%20Conexiones.png" alt="{{webDatos?.Tit}} | {{webDatos?.Des}}" /></a>-->
			<a href="/" title="{{webDatos?.Tit}}" style="display: inline-block;"><img src="{{Url}}{{webDatos?.WebSitLog}}"
					alt="{{webDatos?.Tit}} | {{webDatos?.Des}}" class="img-responsive" style="max-height: 80px;"/></a>


			<!--<h1><a routerLink="/">{{ webDatos?.Tit }} <i class="fa fa-shopping-bag top_logo_agile_bag" aria-hidden="true"></i></a></h1>-->
		</div>
		<!-- header-bot -->
		<div class="col-md-4 agileits-social top_content">
			<ul class="social-nav model-3d-0 footer-social w3_agile_social">
				<li class="share">Siguenos en : </li>
				<li *ngFor="let red of redesDatos"><a href="{{red?.RedLin}}" target="_blank" class="{{red?.RedDes}}">
						<div class="front"><i class="{{red?.RedIco}}" aria-hidden="true"></i></div>
						<div class="back"><i class="{{red?.RedIco}}" aria-hidden="true"></i></div>
					</a>
				</li>
			</ul>
		</div>
		<div class="clearfix"></div>
	</div>
</div>

<div class="ban-top fixed-menu">
	<div class="container">
		<div class="top_nav_left">
			<nav class="navbar navbar-default">
				<div class="container-fluid">
					<!-- Brand and toggle get grouped for better mobile display -->
					<div class="navbar-header">
						<button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
							data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
							<span class="sr-only">Toggle navigation</span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
						</button>
					</div>
					<!-- Collect the nav links, forms, and other content for toggling -->
					<div class="collapse navbar-collapse menu--shylock" id="bs-example-navbar-collapse-1">
						<ul class="nav navbar-nav menu__list">
							<!--<li *ngFor="let secMenu of seccionDatos" class="active menu__item menu__item--current"><a class="menu__link" href="/{{secMenu.Url}}">{{ secMenu?.Tit }} <span class="sr-only">(current)</span></a></li>-->
							<li *ngFor="let secMenu of seccionDatos" class="active menu__item menu__item"><a
									class="menu__link" routerLink="/{{secMenu.Url}}">{{ secMenu?.Tit }} <span
										class="sr-only">(current)</span></a></li>
						</ul>
					</div>
				</div>
			</nav>
		</div>

		<div class="top_nav_right">
			<div class="wthreecartaits wthreecartaits2 cart cart box_1">
				<button class="w3view-cart" routerLink="/cart">
					<div class="icon">
						<i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
						<span>{{cartItem}}</span>
					</div>
					<!--<h5>Catalogo</h5>-->
				</button>
			</div>
		</div>
		<div class="clearfix"></div>
	</div>
</div>

<!-- Modal1 -->
<div class="modal fade" id="myModal2" tabindex="-1" role="dialog">
	<div class="modal-dialog">
		<!-- Modal content-->
		<div class="container" style="background-color: white;">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body modal-body-sub_agile">
				<app-cart></app-cart>
			</div>

		</div>
		<!-- //Modal content-->
	</div>
</div>
<!-- //Modal1 -->

<router-outlet></router-outlet>

<div class="coupons">
	<div class="coupons-grids text-center">
		<div class="w3layouts_mail_grid">
			<div class="col-md-3 w3layouts_mail_grid_left">
				<div class="w3layouts_mail_grid_left1 hvr-radial-out">
					<i class="fa fa-truck" aria-hidden="true"></i>
				</div>
				<div class="w3layouts_mail_grid_left2">
					<h3>ENVIOS</h3>
					<p>Envios a todo el sur del pais.</p>
				</div>
			</div>
			<div class="col-md-3 w3layouts_mail_grid_left">
				<div class="w3layouts_mail_grid_left1 hvr-radial-out">
					<i class="fa fa-headphones" aria-hidden="true"></i>
				</div>
				<div class="w3layouts_mail_grid_left2">
					<h3>Soporte 24/7</h3>
					<p>{{webDatos?.Tit}} esta siempre disponible para ti!</p>
				</div>
			</div>
			<div class="col-md-3 w3layouts_mail_grid_left">
				<div class="w3layouts_mail_grid_left1 hvr-radial-out">
					<i class="fa fa-shopping-bag" aria-hidden="true"></i>
				</div>
				<div class="w3layouts_mail_grid_left2">
					<h3>METODOS DE PAGO</h3>
					<p>Contamos con diferentes metodo de pago.</p>
				</div>
			</div>
			<div class="col-md-3 w3layouts_mail_grid_left">
				<div class="w3layouts_mail_grid_left1 hvr-radial-out">
					<i class="fa fa-gift" aria-hidden="true"></i>
				</div>
				<div class="w3layouts_mail_grid_left2">
					<h3>DESCUENTOS</h3>
					<p>Descuentos exclusivos a nuevos y antiguos clientes.</p>
				</div>
			</div>
			<div class="clearfix"> </div>
		</div>

	</div>
</div>

<div class="footer">
	<div class="footer_agile_inner_info_w3l">
		<div class="col-md-4 footer-left">
			<h2><a routerLink="/">{{ webDatos?.Tit}} </a></h2>
			<p id="texto">{{ webDatos?.Des }}</p>
			<ul class="social-nav model-3d-0 footer-social w3_agile_social two">
				<li *ngFor="let red2 of redesDatos"><a href="{{red2?.RedLin}}" target="_blank"
						class="{{ red2?.RedDes }}">
						<div class="front"><i class="{{ red2?.RedIco }}" aria-hidden="true"></i></div>
						<div class="back"><i class="{{ red2?.RedIco }}" aria-hidden="true"></i></div>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-md-8 footer-right">
			<div class="sign-grds">
				<div class="col-md-4 sign-gd">
					<h4>Mas <span>Informacion</span> </h4>
					<ul>
						<li *ngFor="let sec2 of seccionDatos"><a routerLink="/{{sec2?.Url}}">{{ sec2?.Tit }}</a></li>
					</ul>
				</div>

				<div class="col-md-8 sign-gd-two">
					<h4><span>Datos para </span> CONTACTARNOS</h4>
					<div class="w3-address">
						<div class="w3-address-grid">
							<div class="w3-address-left">
								<i class="fa fa-phone" aria-hidden="true"></i>
							</div>
							<div class="w3-address-right">
								<h6>Numero de contacto</h6>
								<p> {{ webDatos?.WebSitNum1 }}</p>
								<p> {{ webDatos?.WebSitNum2 }}</p>
								<p> {{ webDatos?.WebSitNum3 }}</p>
							</div>
							<div class="clearfix"> </div>
						</div>
						<div class="w3-address-grid">
							<div class="w3-address-left">
								<i class="fa fa-envelope" aria-hidden="true"></i>
							</div>
							<div class="w3-address-right">
								<h6>Correos electronicos</h6>
								<p>Email 1:<a href="{{ webDatos?.WebSitEma1 }}" target="_blank"> {{ webDatos?.WebSitEma1
										}} </a></p>
								<p>Email 2:<a href="{{ webDatos?.WebSitEma2 }}" target="_blank"> {{ webDatos?.WebSitEma2
										}} </a></p>
							</div>
							<div class="clearfix"> </div>
						</div>
						<div class="w3-address-grid">
							<div class="w3-address-left">
								<i class="fa fa-map-marker" aria-hidden="true"></i>
							</div>
							<div class="w3-address-right">
								<h6>Ubicacion</h6>
								<p>{{ webDatos?.WebSitDir1 }}.</p>
								<p>{{ webDatos?.WebSitDir2 }}.</p>
							</div>
							<div class="clearfix"> </div>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>
		<div class="clearfix"></div>
		<div class="agile_newsletter_footer">
			<div class="col-sm-6 newsleft">
				<h3>SUSCRIBIRSE PARA NUEVAS OFERTAS!</h3>
			</div>
			<div class="col-sm-6 newsright">
				<form action="#" method="post">
					<input type="email" placeholder="Ingrese su email..." name="email" required="">
					<input type="submit" value="Enviar">
				</form>
			</div>

			<div class="clearfix"></div>
		</div>
		<p class="copy-right">&copy 2021 virex technology. All rights reserved | Design by <a
				href="http://virextec.com/" target="_blank">virextec</a></p>
	</div>
</div>
<!-- //footer -->