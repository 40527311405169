import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/services/web.service';
import { ProductComponent } from '../product/product.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private webService: WebService) { }

  public Url='https://paginas.virextec.com/archivos/';

  public empresaDatos:any;
  public redesDatos:any;  
  public seccionDatos:any;
  public listaSecciones:any;



  ngOnInit(): void {

    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let scope=this;

    //let pathArray = window.location.hash.split('/');    
    let seccion = window.location.pathname.split('/')[1];
    //let pro = pathArray[2];

    let dom='matconaqp.com';
    let org:number= 9;
    let web:number= 8;

    scope.miPromesa.then(function(){
      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{
        scope.empresaDatos = data.web[0];
        //scope.empresaDatos.Des = scope.empresaDatos.Des.split("\n").join('<br/>');
        scope.redesDatos = data.redes;

        let newObj = data.sections.filter((value)=>{
          return value.Url.indexOf(seccion) != -1 ? value:null
        });

        scope.seccionDatos=newObj[0];

        let newObj2 = data.sections.filter((value)=>{
          return value.Ord.indexOf('2') != -1 ? value:null
        });
        scope.listaSecciones=newObj2;
      })
    })
  }

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  });

}
