import { Component, OnInit } from '@angular/core';
import { Product } from './models/product';
import { MessengerService } from './services/messenger.service';
import { WebService } from './services/web.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  public webDatos:any;
  public seccionDatos:any;
  public redesDatos:any; 
  public productos: Product[] = []; // Agrega esta propiedad
  public resultadosBusqueda: Product[] = [];

  cartItem = 0;
  public Url='https://paginas.virextec.com/archivos/';

  constructor(private webService:WebService, private msg:MessengerService){
    this.msg.cartSubject.subscribe((data)=>{
      this.cartItem = data;
    })
  }
  
  
  ngOnInit():void{
    
     let scope=this;
     //scope.cartItemFunc();    

     let link = window.location;
     let dom='matconaqp.com';
      let org:number= 9;
      let web:number= 8;    

     let miPromesa = new Promise(function(resolve:any, reject:any){
       resolve()
     });

     miPromesa.then(function(){
       scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{   
        console.log('midata...', data)      
        scope.webDatos=data.web[0];
        scope.seccionDatos=data.sections;
        scope.redesDatos=data.redes;   
        scope.productos = data.proAll;      
       })
     });

     window.addEventListener('scroll', () => {
      const menu = document.querySelector('.top_nav_left .top_nav_right nav');
      if (menu) {
        if (window.scrollY > 100) {
          menu.classList.add('fixed-menu');
        } else {
          menu.classList.remove('fixed-menu');
        }
      }
     });

  }

  buscarProducto(query: string) {
    // Lógica de búsqueda en base a tu criterio
    if (query.trim() !== '') {
      this.resultadosBusqueda = this.productos.filter(producto =>
          producto.ProNom.toLowerCase().includes(query.toLowerCase())
      );
    } else {
        this.resultadosBusqueda = [];
    }
    /*this.resultadosBusqueda = this.productos.filter(producto =>
      producto.ProNom.toLowerCase().includes(query.toLowerCase())
    );*/
  }

  /*cartItemFunc(){
    if(localStorage.getItem('localCart')!=null){
      var cartCount = JSON.parse(localStorage.getItem('localCart'));
      this.cartItem = cartCount.length; 
      this.msg.cartSubject.next(this.cartItem);
    }
  }*/
}
