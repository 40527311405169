<div class="container">
  <div *ngIf="getCartDetails.length === 0" class="alert alert-info col-md-11" >No hay items en el carrito</div>
  <table class="table table-striped col-md-12">
    <thead class="col-md-12">
      <tr class="col-md-11" >
        <th class="col-md-1" scope="col">#</th>
        <th class="col-md-1" scope="col">Imagen</th>
        <th class="col-md-4" scope="col">Nombre</th>
        <th class="col-md-1" scope="col">Medida</th>
        <th class="col-md-3" scope="col">Cant.</th>
        <th class="col-md-2" scope="col">Accion</th>
      </tr>
    </thead>
    <tbody class="col-md-12">
      <tr class="col-md-11" *ngFor="let item2 of getCartDetails">
        <th class="col-md-1" scope="row">{{ item2.ProID }}</th>
        <td class="col-md-1" style="height: 90px;"><img src="{{Url}}{{ item2?.ProDirImg2 }}" alt="" style="max-height: 95%;"/></td>
        <td class="col-md-4">{{ item2.ProNom }}</td>
        <td class="col-md-1">
          <input class="form-control" type="text" name="medida" (change)="incMed(item2.ProID, $event)" [value]="item2.ProMed" required autocomplete="off"/>            
        </td>
        <td class="col-md-3">
          <input class="form-control" type="number" name="cant" (change)="incCan(item2.ProID, $event)" [value]="item2.ProCan" required autocomplete="off"/>            
        </td>
        <td class="col-md-1"><button type="button" class="btn btn-danger" (click)="singleDeleter(item2.ProID)"><i class="fa fa-trash"></i></button></td>
      </tr>
    </tbody>
  </table>
  <div class="row" class="col-md-11 text-right">
    <div  >
      <button type="button" class="btn btn-danger" (click)="removeAll()" style="margin-right: 5%;"><i class="fa fa-trash"></i> Eliminar todo</button>
    </div>
  </div>
  <form [formGroup]="aFormGroup" style="width: 70%; margin: auto;"  >
    <div>
      <label>Nombre:</label>
      <input class="form-control" type="text" [formControl]="name" name="Name" required autocomplete="off" />      
      <span></span>
    </div>
    <div>
      <label>Email:</label>
      <input class="form-control" type="email"  [formControl]="email" name="Email" required autocomplete="off" />      
      <span></span>
    </div>
    <br/>

    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"> </ngx-recaptcha2>

    <div *ngIf="statusMsn === 2" class="alert alert-warning col-md-11" >{{msnSend}}</div>
    <div *ngIf="statusMsn === 1" class="alert alert-success col-md-11" >{{msnSend}}</div>
    <div *ngIf="statusMsn === 3" class="alert alert-info col-md-11" >{{msnSend}}</div>

    <div class="text-right">
      <button [disabled]="" type="submit" class="btn btn-success" (click)="sendCart()">ENVIAR</button> 
    </div>
  </form>
</div>
  
