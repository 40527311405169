<!--<script type="text/javascript" src='./assets/js/jquery-2.1.4.min.js'></script> -->

<div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
        <li *ngFor="let sli2 of sliderDatos" data-target="#myCarousel" attr.data-slide-to="{{sli2?.SliOrd}}" class="{{sli2?.SliCla}}"></li>
        <!--<li data-target="#myCarousel" data-slide-to="1" class=""></li>
        <li data-target="#myCarousel" data-slide-to="2" class=""></li>
        <li data-target="#myCarousel" data-slide-to="3" class=""></li>
        <li data-target="#myCarousel" data-slide-to="4" class=""></li> -->
    </ol>
    <div class="carousel-inner" role="listbox">
        <div *ngFor="let sli of sliderDatos" class="item {{sli?.SliCla}}" style="background-image: url('{{Url}}{{sli?.SliDirImg}}')"> 
            <div class="container">
                <div class="carousel-caption">
                    <h3>{{sli?.SliTex1}} <span>{{sli?.SliTex2}}</span></h3>
                    <p>{{sli?.SliTex3}}</p>
                    <a class="hvr-outline-out button2" routerLink="{{sli?.SliLin}}">Ver Mas</a>
                </div>
            </div>
        </div> 
    </div>
    <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div> 

<div class="banner_bottom_agile_info">
    <div class="container">            
        <div class="banner_bottom_agile_info_inner_w3ls">
               <div *ngFor="let sec of listaSecciones" class="col-md-6 wthree_banner_bottom_grid_three_left1 grid">
                    <figure class="effect-roxy">
                        <img src="{{Url}}{{sec?.Img}}" alt=" " class="img-responsive" />
                        <figcaption>
                            <h3>Nuestros <span>{{sec?.Tit}}</span></h3>
                            <a routerLink="{{sec?.Url}}" class=""> <p>Ver {{sec?.Tit}}</p></a>
                        </figcaption>			
                    </figure>
                </div>
                <div class="clearfix"></div>
        </div>        
     </div> 
</div>
<!-- /new_arrivals --> 

<div class="new_arrivals_agile_w3ls_info" style="padding-top: 0;">
	<div class="container">
        <h3 class="wthree_text_info">Nuevos <span>Productos</span></h3>
        <div class="single-pro">
			<div *ngFor="let pro of productosDatos" class="col-md-3 product-men">
                <div class="men-pro-item simpleCart_shelfItem">
                    <div class="men-thumb-item">
                        <img src="{{Url}}{{pro?.ProDirImg1}}" alt="" class="pro-image-front">
                        <img src="{{Url}}{{pro?.ProDirImg3}}" alt="" class="pro-image-back">
                        <div class="men-cart-pro">
                            <div class="inner-men-cart-pro">
                                <a routerLink="/productos/{{pro?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                            </div>
                        </div>
                        <span class="product-new-top">{{pro?.ProPorOfe}}</span>
                                                  
                    </div>

                    <div class="item-info-product ">
                        <h4><a routerLink="/productos/{{pro?.ProID}}">{{ pro?.ProNom }}</a></h4>
                        <!--<h4><a [routerLink]="['/producto/', pro?.ProID ]">{{ pro?.ProNom }}</a></h4>-->
                        <div class="info-product-price" *ngIf=" pro?.ProPreOfe > 0 ">
                            <span class="item_price">S/.{{ pro?.ProPreOfe }}</span>
                            <del>S/.{{ pro?.ProPre }}</del>
                        </div>
                        <div class="info-product-price" *ngIf=" pro?.ProPreOfe == 0 || !pro?.ProPreOfe">
                            <span class="item_price">S/.{{ pro?.ProPre }}</span>
                        </div>
                        <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                            <form action="#" method="post">
                                <fieldset>
                                    <input type="button" name="submit" value="Cotizar" class="button" 
                                    (click)="handleAddToCard(pro)"/>
                                </fieldset>
                            </form>
                        </div>                                                            
                    </div>
                </div>
            </div>			
			<div class="clearfix"></div>
		</div>
	</div>
</div>

<!--
<div class="new_arrivals_agile_w3ls_info"> 
    <div class="container">
        <h3 class="wthree_text_info">Nuevos <span>Productos</span></h3>		
        <div id="horizontalTab">
            <ul class="resp-tabs-list">
                <li> TODOS </li>
                <li> ADAPTADORES</li>
                <li> GAMER </li>                
                <li> ESCRITORIO </li>                
                <li *ngFor="let niv1 of listaNivel1">{{niv1?.ProNom}}</li>
            </ul>
            <div class="resp-tabs-container">                
                <div class="tab1">
                    <div *ngFor="let proTod of productosDatos" class="col-md-3 product-men">
                        <div class="men-pro-item simpleCart_shelfItem">
                            <div class="men-thumb-item">
                                <img src="{{Url}}{{proTod?.ProDirImg2}}" alt="" class="pro-image-front">
                                <img src="{{Url}}{{proTod?.ProDirImg3}}" alt="" class="pro-image-back">
                                <div class="men-cart-pro">
                                    <div class="inner-men-cart-pro">
                                        <a routerLink="/productos/{{proTod?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                                    </div>
                                </div>
                                <span class="product-new-top">{{proTod?.ProPorOfe}}</span>                                    
                            </div>
                            <div class="item-info-product ">
                                <h4><a routerLink="/productos/{{proTod?.ProID}}">{{ proTod?.ProNom }}</a></h4>
                                <div class="info-product-price">
                                    Desde <span class="item_price">{{ proTod?.ProMedMin }}</span>
                                    Hasta <span class="item_price">{{ proTod?.ProMedMax }}</span>
                                    
                                </div>
                                <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                                    <form action="#" method="post">
                                        <fieldset>                                            
                                            <input type="button" name="submit" value="Cotizar" class="button" 
                                            (click)="handleAddToCard(proTod)"/>                                             
                                        </fieldset>                                        
                                    </form>
                                </div>                                                                   
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

                <div class="tab2">
                    <div *ngFor="let conex of listaConexiones" class="col-md-3 product-men">
                        <div class="men-pro-item simpleCart_shelfItem">
                            <div class="men-thumb-item">
                                <img src="{{Url}}{{conex?.ProDirImg2}}" alt="" class="pro-image-front">
                                <img src="{{Url}}{{conex?.ProDirImg3}}" alt="" class="pro-image-back">
                                <div class="men-cart-pro">
                                    <div class="inner-men-cart-pro">
                                        <a routerLink="/productos/{{conex?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                                    </div>
                                </div>
                                <span class="product-new-top">{{conex?.ProPorOfe}}</span>                                    
                            </div>
                            <div class="item-info-product ">
                                <h4><a routerLink="/productos/{{conex?.ProID}}">{{ conex?.ProNom }}</a></h4>
                                <div class="info-product-price">
                                    Desde <span class="item_price">{{ conex?.ProMedMin }}</span>
                                    Hasta <span class="item_price">{{ conex?.ProMedMax }}</span>
                                    
                                </div>
                                <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                                    <form action="#" method="post">
                                        <fieldset>                                            
                                            <input type="button" name="submit" value="Cotizar" class="button" 
                                            (click)="handleAddToCard(conex)"/>
                                        </fieldset>
                                    </form>
                                </div>                                                                    
                            </div>
                        </div>
                    </div>                           
                    <div class="clearfix"></div>
                </div>
                 
                <div class="tab3">
                            
                    <div *ngFor="let tube of listaTuberia" class="col-md-3 product-men">
                        <div class="men-pro-item simpleCart_shelfItem">
                            <div class="men-thumb-item">
                                <img src="{{Url}}{{tube?.ProDirImg2}}" alt="" class="pro-image-front">
                                <img src="{{Url}}{{tube?.ProDirImg3}}" alt="" class="pro-image-back">
                                <div class="men-cart-pro">
                                    <div class="inner-men-cart-pro">
                                        <a routerLink="/productos/{{tube?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                                    </div>
                                </div>
                                <span class="product-new-top">{{tube?.ProPorOfe}}</span>                                    
                            </div>
                            <div class="item-info-product ">
                                <h4><a routerLink="/productos/{{tube?.ProID}}">{{ tube?.ProNom }}</a></h4>
                                <div class="info-product-price">
                                    Desde <span class="item_price">{{ tube?.ProMedMin }}</span>
                                    Hasta <span class="item_price">{{ tube?.ProMedMax }}</span>
                                    
                                </div>
                                <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                                    <form action="#" method="post">
                                        <fieldset>                                            
                                            <input type="button" name="submit" value="Cotizar" class="button" 
                                            (click)="handleAddToCard(tube)"/>
                                        </fieldset>
                                    </form>
                                </div>                                                                    
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>


                <div class="tab4">
                    <div *ngFor="let val of listaValvulas" class="col-md-3 product-men">
                        <div class="men-pro-item simpleCart_shelfItem">
                            <div class="men-thumb-item">
                                <img src="{{Url}}{{val?.ProDirImg2}}" alt="" class="pro-image-front">
                                <img src="{{Url}}{{val?.ProDirImg3}}" alt="" class="pro-image-back">
                                <div class="men-cart-pro">
                                    <div class="inner-men-cart-pro">
                                        <a routerLink="/productos/{{val?.ProID}}" class="link-product-add-cart">Ver Producto</a>
                                    </div>
                                </div>
                                <span class="product-new-top">{{val?.ProPorOfe}}</span>                                    
                            </div>
                            <div class="item-info-product ">
                                <h4><a routerLink="/productos/{{val?.ProID}}">{{ val?.ProNom }}</a></h4>
                                <div class="info-product-price">
                                    Desde <span class="item_price">{{ val?.ProMedMin }}</span>
                                    Hasta <span class="item_price">{{ val?.ProMedMax }}</span>
                                    
                                </div>
                                <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                                    <form action="#" method="post">
                                        <fieldset>
                                            <input type="button" name="submit" value="Cotizar" class="button" 
                                            (click)="handleAddToCard(val)"/>
                                        </fieldset>
                                    </form>
                                </div>                                                                    
                            </div>
                        </div>
                    </div>                       
                    
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>	
    </div>
</div> -->